import { initializeApp } from "firebase/app";
const firebaseConfig = {
	apiKey: "AIzaSyC0pHmfqAjnvMlAXdC2TiLXkowh5QKsyLY",
	authDomain: "sunny-audio.firebaseapp.com",
	projectId: "sunny-audio",
	storageBucket: "sunny-audio.appspot.com",
	messagingSenderId: "627544794905",
	appId: "1:627544794905:web:b8726d08f2853dd3e94788",
	measurementId: "G-05WP09XLKR"
};
let firebaseInstance
const getFirebase = ()=>{
	if(firebaseInstance){
		return firebaseInstance
	}

	firebaseInstance = initializeApp(firebaseConfig)

	return firebaseInstance
}

export default getFirebase